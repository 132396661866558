<template>
  <div>
    <el-container>
      <!-- width的宽度跟collapse一样动态控制 -->
      <el-aside width="collapse">
        <el-menu :default-active="$route.path" router :collapse="isCollapse" class="el-menu-vertical" background-color="#304156"
                 text-color="#fff" active-text-color="rgb(64, 158, 255)">
          <el-menu-item index="/dashboard">
            <template slot="title">
              <i class="el-icon-s-custom"></i>
              <span>荆州宏胜科技管理后台</span>
            </template>
          </el-menu-item>
          <el-submenu index="2">
            <template slot="title">
              <i class="el-icon-date"></i>
              <span slot="title">页面录入</span>
            </template>
            <el-menu-item index="/page">模块录入</el-menu-item>
          </el-submenu>

<!--          <el-submenu index="4">
            <template slot="title">
              <i class="el-icon-document"></i>
              <span slot="title">行业新闻</span>
            </template>
            <el-menu-item index="/table">行业新闻录入</el-menu-item>
          </el-submenu>-->
        </el-menu>
      </el-aside>
      <el-container>
        <el-header>
          <div class="container">
            <div class="div1">
              <div :title="isCollapse ? '点击展开' : '点击收起'" class="box_bgd" @click="isC">
                <!-- 点击展开收起导航和切换对应图标 -->
                <i :class="!isCollapse ? 'el-icon-s-fold' : 'el-icon-s-unfold'"></i>
              </div>
            </div>
            <div class="div2">
              <div class="right-menu">
                <el-dropdown class="avatar-container" trigger="click">
                  <div class="avatar-wrapper">
                    <img src="../../assets/images/icon/touxiang.gif" class="user-avatar">
                    <i class="el-icon-caret-bottom" />
                  </div>
                  <el-dropdown-menu slot="dropdown" class="user-dropdown">
                    <router-link to="/">
                      <el-dropdown-item>
                        Home
                      </el-dropdown-item>
                    </router-link>
                    <a target="_blank" href="https://github.com/PanJiaChen/vue-admin-template/">
                      <el-dropdown-item>Github</el-dropdown-item>
                    </a>
                    <a target="_blank" href="https://panjiachen.github.io/vue-element-admin-site/#/">
                      <el-dropdown-item>Docs</el-dropdown-item>
                    </a>
                    <!--                <el-dropdown-item divided @click.native="logout">
                                      <span style="display:block;">Log Out</span>
                                    </el-dropdown-item>-->
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
          </div>
        </el-header>
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isCollapse: false, //默认为展开
    };
  },
  methods: {
    // 动态控制展开与收起和切换对应图标
    isC() {
      this.isCollapse = !this.isCollapse;
    },
  },
};
</script>

<style scoped>
.el-header {
  background: white;
  color: #333;
  padding: 0 !important;
}

.el-aside {
  background: #304156;
  color: #3e97f3;
  height: 100vh;
}
.el-menu {
  border-right-width: 0;
}
.el-main {
  color: #333;
}
.el-menu-vertical:not(.el-menu--collapse) {
  width: 240px;
}
.box_bgd {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.box_bgd i {
  font-size: 20px;
}
.box_bgd:hover {
  background: rgb(203, 215, 230);
}
.right-menu {
  float: right;
  height: 100%;
  line-height: 50px;

  &:focus {
    outline: none;
  }

  .right-menu-item {
    display: inline-block;
    padding: 0 8px;
    height: 100%;
    font-size: 18px;
    color: #5a5e66;
    vertical-align: text-bottom;

    &.hover-effect {
      cursor: pointer;
      transition: background .3s;

      &:hover {
        background: rgba(0, 0, 0, .025)
      }
    }
  }

  .avatar-container {
    margin-right: 30px;

    .avatar-wrapper {
      margin-top: 5px;
      position: relative;

      .user-avatar {
        cursor: pointer;
        width: 40px;
        height: 40px;
        border-radius: 10px;
      }

      .el-icon-caret-bottom {
        cursor: pointer;
        position: absolute;
        right: -20px;
        top: 25px;
        font-size: 12px;
      }
    }
  }
}
/* div1样式 */
.container div:first-child {
  order: -1; /* 调整顺序，让Div1在前面显示 */
}

/* div2样式 */
.container div:last-child {
  margin-left: auto; /* 自动添加margin，使得Div2位于最后 */
}
.container{
  display: flex;
}
</style>
