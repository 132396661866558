<template>
  <div>
    <div style="margin-bottom: 15px">
      <el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button>
    </div>
    <el-table :data="tableData"  border style="width: 100%">
      <el-table-column type="index" width="50" label="序号"></el-table-column>
      <el-table-column prop="id" width="50" label="id" v-if="false"></el-table-column>
      <el-table-column
          label="模块名称">
        <template slot-scope="scope">
          {{ scope.row.typeid ? statusData[(scope.row.typeid)].label1 : scope.row.typeid  }}
        </template>
      </el-table-column>
      <el-table-column prop="title" label="标题" width="180"></el-table-column>
      <el-table-column prop="content" label="内容"></el-table-column>
      <el-table-column prop="jump" label="链接地址"></el-table-column>
      <el-table-column fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <el-button @click="handleUpdate(scope.row)" type="text" size="small">修改</el-button>
          <el-button type="text" size="small" @click="handleDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :title="titleOp" :visible.sync="dialogFormVisible">
      <el-form :model="form">
        <el-form-item label="模块名称" :label-width="formLabelWidth">
          <el-select v-model="form.typeid" placeholder="请选择模块名称">
            <el-option label="标杆案例" value="HYAL"></el-option>
            <el-option label="行业新闻" value="HYXW"></el-option>
            <el-option label="最新动态" value="ZXDT"></el-option>
          </el-select>
        </el-form-item>
        <!--        <el-form-item label="类型" :label-width="formLabelWidth">
                  <el-tag>产品案例</el-tag>
                </el-form-item>-->
        <el-form-item label="标题" :label-width="formLabelWidth">
          <el-input v-model="form.title" placeholder="请输入内容"></el-input>
        </el-form-item>
        <el-form-item label="案例类型" :label-width="formLabelWidth">
          <el-select v-model="form.remark1" placeholder="请选择案例类型">
            <el-option label="政府案例" value="government"></el-option>
            <el-option label="教育案例" value="education"></el-option>
            <el-option label="医疗案例" value="health"></el-option>
            <el-option label="企业案例" value="enterprise"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="内容" :label-width="formLabelWidth">
          <el-input type="textarea" v-model="form.content"></el-input>
        </el-form-item>
        <el-form-item label="链接地址" :label-width="formLabelWidth">
          <el-input v-model="form.jump"></el-input>
        </el-form-item>
        <el-dialog :visible.sync="dialogVisible">
          <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  data() {
    return {
      dialogFormVisible: false,
      // 弹出层标题
      titleOp: "",
      form: {
        id:'',
        typeid: '',
        title: '',
        content: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: '',
        ifCustom: null,
        jump:'',
        remark1:''
      },
      formLabelWidth: '120px',
      input: '',
      desc: '',
      dialogImageUrl: '',
      dialogVisible: false,
      tableData: [],
      ac: [],
      statusData: {
        'HYAL': {
          label1: '标杆案例'
        },
        'HYXW': {
          label1: '行业新闻'
        },
        'ZXDT': {
          label1: '最新动态'
        }
      },
      reset() {
        this.form = {
          id: '',
          typeid: '',
          title: '',
          content: '',
          date2: '',
          delivery: false,
          type: [],
          resource: '',
          desc: '',
          ifCustom: null,
          jump:'',
          remark1:''
        }
      }


    }

  },
  mounted() {
    this.getCompanyInfo()
    this.getdata()
    this.updatedata()
    this.selectId()
  },
  created() {

  },
  methods: {
    getCompanyInfo() {
      this.getRequest("/website/queryAll").then(resp => {
        if (resp) {
          this.tableData = resp.data.data
        }
        /*console.log(this.tableData, "2024测试")*/
      })
    },
    getdata(data) {
      /*console.log(data)*/
      // 4、发送axios请求
      this.postRequest("/website/add", data).then((resp) => {
       /* console.log(resp, "ceshi2024")*/
        this.dialogFormVisible = false
        if (resp) {
          this.tableData = resp.data.data
        }
        this.getCompanyInfo();

      });
    },


    updatedata(data) {
      /*console.log(data)*/
      // 4、发送axios请求
      this.postRequest("/website/update", data).then((resp) => {
        /*console.log(resp, "ceshi2024")*/
        this.getCompanyInfo();

      });
    },


    selectId(form) {
      let id = form.id
      this.postRequest("/website/findWebId", id).then((resp) => {
       /* console.log(resp, "ceshi2024222")*/


      });
    },

    handleClick(row) {
      this.dialogFormVisible = true
      /*console.log(row);*/
      this.form = row;
      this.selectId(this.form);
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset(); //置空 方法

      this.dialogFormVisible = true;  //打开弹窗

      this.titleOp = "新增数据";  //标题改变
    },

    handleUpdate(row) {
      this.reset();  //置空
      this.form.id=row.id
      this.form.title=row.title
      this.form.typeid=row.typeid
      this.form.content=row.content
      this.form.jump=row.jump
      this.dialogFormVisible = true;  //打开弹窗

      this.titleOp = "修改数据";  //标题改变

    },
    handleDelete(row){
      let id =row.id
      let data =row
     /* console.log(id,"id000")*/
      this.postRequest("/website/deleteId",data).then(resp => {
        this.$message({
          message: '删除成功!!!',
          type: 'success'
        });
        this.getCompanyInfo();
        /*console.log(resp, "删除成功")*/
      })

    },
    /** 提交按钮 */
    submitForm() {
      /*console.log(this.form.id,"测试id")
      console.log(this.form,"测试form")*/
      if (this.form.id !="".trim()) {
        this.updatedata(this.form);
        this.dialogFormVisible = false;  //关闭弹窗
        this.$message({
          message: '修改成功!!!',
          type: 'success'
        });
      } else {
        this.getdata(this.form)
        this.dialogFormVisible = false;  //关闭弹窗
        this.$message({
          message: '新增成功!!!',
          type: 'success'
        });
      }

    },

  }
}
</script>
