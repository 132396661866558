// 引入 axios
import axios from "axios";



let base = 'http://47.98.162.103:8081';
/*let base = 'http://localhost:8081';*/

//传送json格式的get请求
export const getRequest=(url,params)=>{
    return axios({
        method:'get',
        url:`${base}${url}`,
        data: params,
    })
}


//使用form格式传参数
export const postRequest = (url, params) => {
    return axios({
        url: `${base}${url}`,
        data: params,
        method: 'post'
    })
}
export const postKeyValueRequest = (url, params) => {
    return axios({
        url: `${base}${url}`,
        data: params,
        method: 'post',
        transformRequest: [function (data) {
            let result = '';
            for (let i in data) {
                result += encodeURIComponent(i) + "=" + encodeURIComponent(data[i]) + '&';
            }
            return result;
        }],
        header:{
            'Content-type':'application/x-www-form-urlencoded'
        }
    })
}
