import Vue from 'vue'
import VueRouter from 'vue-router'
import dashboard from "@/views/dashboard/index.vue";
import page from "@/components/page/index.vue";

Vue.use(VueRouter)

const routes = [
    {
        path:'/',
        redirect:'/index'
    },
    {
        path: '/index',
        name: 'index',
        component: () => import('../views/IndexView.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/login/index.vue')
    },
    {
        path: '/product',
        name: 'product',
        component: () => import('../views/ProductView.vue'),
    },
    {
        path: '/example',
        name: 'example',
        component: () => import('../views/ExampleView.vue')
    },
    {
        path: '/news',
        name: 'news',
        component: () => import('../views/NewsView.vue')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('../views/AboutView.vue')
    },
    {
        path: '/case',
        name: 'case',
        component: () => import('../views/caseView.vue')
    },
    {
        path: '/mapView',
        name: 'mapView',
        component: () => import('../views/mapView.vue')
    },
    {
        path: '/certificate',
        name: 'certificate',
        component: () => import('../components/certificate/index.vue')
    },
    {
        path: '/special',
        name: 'special',
        component: () => import('../views/special/index.vue')
    },
    {
        path: '/honors',
        name: 'honors',
        component: () => import('../components/honors/index.vue')
    },
    {
        path: '/cases',
        name: 'cases',
        component: () => import('../components/cases/index.vue')
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('../views/dashboard/index.vue')
    },
    {
        path:'/dashboard',
        name:'dashboard',
        component:dashboard,
        children:[
    {
        path:'/page',
        name:'page',
        component:page
    },

]
}

]

const router = new VueRouter({
    routes
})

export default router
