import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/css/style.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/en' // lang i18n
import {getRequest} from "@/api/api";
import {postRequest} from "@/api/api";
import VideoPlayer from 'vue-video-player/src';
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
/*import 'bootstrap/dist/css/bootstrap.min.css';*/

Vue.use(VideoPlayer)
Vue.prototype.getRequest = getRequest;
Vue.prototype.postRequest = postRequest;
// set ElementUI lang to EN
Vue.use(ElementUI, { locale })

Vue.config.productionTip = false
import VueAMap from 'vue-amap'
Vue.use(VueAMap);
// 初始化vue-amap
VueAMap.initAMapApiLoader({
  key: '5395090e672ebf554e9e6eb7b95da531',// 高德的key
  // 插件集合
  plugin: [
    'AMap.CircleEditor',// 圆形编辑器插件
    "AMap.Geolocation", // 定位控件，用来获取和展示用户主机所在的经纬度位置
    "AMap.Geocoder", // 地理编码与逆地理编码服务，用于地址描述与坐标间的相互转换
    "AMap.Autocomplete",
    "AMap.PlaceSearch",
    "AMap.CitySearch",
  ],
  // 高德 sdk 版本，默认为 1.4.4
  v: '1.4.4'
});
//高德的安全密钥
window._AMapSecurityConfig = {
  securityJsCode:'40260bddaf3c39c66d7c325003a4181d',
}


new Vue({
  el: '#app',
  router,
  render: h => h(App)
}).$mount('#app')
